
body {
  margin:0 !important;
  padding:0;
  font-family: "Yu Gothic", Arial, sans-serif  !important;
  color:  #3E3A39 !important;
}

.main {
  padding-bottom: 60px;
}

.bi {
  font-size: 2rem; 
  color: cornflowerblue;
}

.ri-fridge-line {
  font-size: 2rem;
  color: #0056b3;
}

h4 {
  font-size: 16px !important;
  text-align: center;
  margin-bottom: 0px  !important;
}


h5 {
  font-size: 14px !important;
  font-weight: bolder;
}
.mt-3 {
  margin: 0px;
}
.page-title {
  font-weight: bolder;
  text-align: center;
  color:rgb(30, 19, 12);
}
label {
  display: block;
  margin-top: 10px;
  font-size: 13px;
}

.titleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addShopping-Wrapper {
  border: rgb(30, 19, 12) solid 1px;
  margin: 15px 0px;
  padding: 0px 10px 10px 10px;
  border-radius: 8px;
}

.addFridge-Wrapper {
  border:  rgb(30, 19, 12) solid 1px;
  margin: 15px 0px;
  padding: 0px 10px 10px 10px;
  border-radius: 8px;
}

.show-inside-fridge-button {
  font-size: 12px !important;
  width: 100%;
  float: right;
}

.add-food-button {
  font-size: 0.8rem !important;
 
}

.update-shopping,.update-fridge {
 font-size: 13px !important;
 height: 30px;
} 

/* .buttonWrapper {
  display: flex;
  align-items: center;
} */

.add-button {
 /* height: 33.19px; */
 width: 100%;
 bottom: 0;
 font-size: 12px !important;
 padding: 7px, 12px !important;
}

.buttonSpacer {
  width: 100%;
  
  height: 32px; /* 適切な高さに調整してください */
}

.delete-button {
  width: 100%;
  height: 30px;
  vertical-align: middle !important;
  font-size: 8px !important;
  background-color:rgb(30, 19, 12) !important;
  border-radius: 10px;
  border: none;
}
.delete-button > span {
  color: white;
}

.fridge-delete-button {
  width: 100%;
  height: 30px;
  vertical-align: middle !important;
  font-size: 8px !important;
  background-color:rgb(30, 19, 12) !important;
  border-radius: 5px;
  border: none;
}
.fridge-delete-button > span {
  color: white;
}

.addForm, .foodAddForm {
  align-items: end;
}
.addForm > .row  > div, .foodAddForm > .row  > div {
  padding: 0;
}

.shoppingUpdateForm > .row > div {
  padding: 0;
}
.fridgeUpdateForm > .row > div {
  padding: 0;
}

button {
  font-size: 14px !important;
}

.login-icon-container {
  max-width: 720px !important;
  padding: 0px 40px 0px;
  margin: 0 auto;
}



 .update-shopping-category {
  height: 31px; /* 任意の高さを指定してください */
  font-size: 13px !important;
}

/* modal */
.custom-modal {
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
  display: flex; 
  justify-content: center;
  align-items: center; 
}

.custom-box {
  margin: auto;
  text-align: center;
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%; 
  max-height: 80%; 
  overflow: auto; 
  z-index: 9999; 
}

.modal-overlay, .modal-overlay-foodList {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明の黒 */
}

.custom-box ul {
  list-style-type: none;
  padding-left: 0;
}

.custom-box  {
  font-size: 0.8rem !important;
 
}

.custom-button {
  text-align: center;
  margin: 0 auto;
}

/* shopping && fridge */
.emptyComment {
  font-size: 0.8rem;
}

/* shopping */

.shopping-start-box > div > div > ul {
  margin-bottom: 0 !important;
}


.shopping-start-box > div > button {
  margin-top: 0 !important;
  margin-bottom: 0.5rem;
}
.shopping-start-box > div > div {
 
  font-size: 0.8rem !important;
  color: rgb(30, 19, 12);
}

.custom-modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(136, 129, 129, 0.5);
}



.button:hover {
  background-color: #0056b3;
}

.button-container {
  justify-content: center;
}
.add-button-container, .return-link {
  float: right;
}

.custom-field {
  width: 50px;
}
.register-button {
  float: right;
}

/* form */
.shoppingUpdateForm > .row > div > .form-control, .shoppingUpdateForm > .row > div > .form-select {
  border: 0.3px solid rgb(30, 19, 12, 0.5) !important;
  border-top: none !important;
  border-radius: 10px;
}

custom-dropdown-menu {
  max-height: 300px; /* 好みに応じて適切な高さを設定 */
  overflow-y: auto; /* メニューがウィンドウ外にはみ出た場合にスクロールバーを表示 */
}

select {
  width: 200px;
  /* height: 1.5rem; */
}

 .categoryName {
  font-weight: bold;
 }

/* nav */
.navbar-brand {
  font-size: 1rem !important;
}
.navbar {
  background-color: rgb(30, 19, 12);
}

.nav-item {
  margin-left: 0.5rem !important;
}

.userName {
  color: rgba(255, 255, 255, 0.671);
  font-size: 0.7rem;
  margin-left: 2rem;
}

/* footer */


footer {
  position: fixed !important;
  display: flex;
  justify-content: center;
  height: 50px;
  bottom: 0;
  width: 100%;
  background-color: rgb(234, 219, 211);
  align-items: center;
}

footer p {
  color: rgb(99, 95, 92);
  font-size: 0.7rem;
  margin-bottom: 0;
}

/* 現在のユーザーのスタイル */
.navbar-nav .current-user {
  font-weight: bold; /* 太字で表示 */
}

@media (max-width: 768px) {
  /* 購入日フィールドの入力ボックスの幅を調整 */
  input[type="date"] {
    width: 100%;
    
  }
  
  
}
@media (min-width: 1000px) {

  .br-mobile {
    display: none;
  }
}



@media (max-width: 290px) {
  .login-icon-container {
    max-width: 720px !important;
    padding: 0px 20px 0px;
    margin: 0 auto;
  }
  .icon-container {
    gap: 10px;
  }
  .icon-container > div > p {
  font-size: 0.9rem;
}

  .add-button {
    white-space: nowrap !important;
    text-align: center;
    font-size: 0.8rem !important;
  }
  .delete-button {
    white-space: nowrap !important;
    font-size: 6px !important;
  }

  .update-button-sm {
    font-size: 0.8rem !important;
  }

  .list-title {
    font-size: 1rem !important;
  }
  .blanch-logo-text {
    font-size: 0.7rem !important;
  }
}

/* login shopping branch*/
.icon-container {
  margin: 60px 0px 0px 0px;
  display: flex; 
  justify-content: center;
  gap: 40px
}

.list-title-wrapper, .list-title-wrapper, .show-button-wrapper, .add-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-title {
  color: rgb(20, 14, 10);
  margin-bottom: 0 !important;
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem;
}

.fa-shopping-basket {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.siteName {
  color:rgb(20, 14, 10);
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
}
.loginName, .registerName {
  margin-top: 3rem;
  margin-bottom: 0;
  text-align: center;
  font-size: 0.8rem;
}

.registerName {
  margin-top: 3rem;
  margin-bottom: 0;
  text-align: center;
  font-size: 1.5rem;
}

/* login */
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/* branch */
.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0; /* 追加 */
}

.logo-link {
   display: flex;
  flex-direction: column;
  align-items: center;
}


.branch-logo-text {
  text-align: center;
  color: rgb(79, 80, 81);
}

.icon-container > div > p {
  text-align: center;
   color:rgb(20, 14, 10);
   font-weight: bold;
}



.return-link, .logo-link {
  text-decoration: none;
}



/* addForm */
.foodAddWindow {
  margin-top: 0 !important;
}

form > div > div > div > div > input, form > div > div > div > div > select {
  font-size: 0.8rem !important;
}

form > div > div > div > div > div > input, form > div > div > div > div > div > select {
  font-size: 0.8rem !important;
}